@import "@/shared/variables";

.primary {
    background-color: white;
    border-radius: $border-radius;
    border: 1.5px solid $color-blue-light;
    width: 100%;
    height: 100%;
    color: $color_blue_light_2;
    font: normal normal bold 12px/16px;

    // padding-left: 10px;
    padding-top: 4px;
    padding-bottom: 4px;

    transition: 0.2s ease;

}

.primary:hover {
    background-color: $color-blue-light;
    color: $color-white;
}

.primary:hover a {
    color: $color-white;
}

.secondary {
    background-color: $color-blue-light;
    border-radius: $border-radius;
    border: $border $color-blue-light;
    width: 100%;
    height: 100%;
    color: white;
    font: normal normal bold 12px/16px;

    transition: 0.2s ease;
}

.secondary:hover {
    background-color: $color-blue-dark;
}

.three {
    background-color: $color-blue-light;
    border-radius: $border-radius;
    border: $border $color-blue-light;
    width: 100%;
    height: 100%;
    color: white;
    font-size: 14px;
    font-weight: bold;

    // padding-left: 10px;
    padding-top: 4px;
    padding-bottom: 4px;

    transition: 0.2s ease;
}

.three:hover {
    background-color: $color_blue_auto;
}

.disabled {
    background-color: $color-gray-light;
    border-radius: $border-radius;
    width: 100%;
    height: 100%;
    color: white;
    font: normal normal bold 12px/16px Nunito Sans;
    padding-left: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    transition: 0.2s ease;
    cursor: default !important;
}

.link {
    color: $color_blue_light;
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.linkLista {
    color: $color_blue_light;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    column-gap: 10px;
    padding: 10px;
}

.linkLista:hover {
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    column-gap: 10px;
    padding: 10px;
    background-color: #002554;
    border-radius: 10px;
}

.icon {
    display: flex;
}

.buttonWithIcon {
  border-radius: 2px;
  padding: 5px;
  background: #F2F2F2;
  width: 188px;
  display: flex;
  justify-content: space-between;
  color: $color-black;
  font-size: 12px;
  text-decoration: none;
  display: flex;
  align-items: center
}


@media screen and (max-width: 768px) {
    .button-one {
      order: 3;
   }

   .button-two {
    order: 1;
   }

   .button-three {
    order: 2;
   }
  }
