@import '@/shared/variables';

$collapser-button-position: 15px;

$sidebar-collapsed-width: 60px;

.collapser-button {
  position: absolute;
  right: -$collapser-button-position;
  top: 20px;
  width: $collapser-button-position;
  height: 30px;
  color: white;
  background-color: #002554b2;

  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.main-container {
  position: relative;
  min-height: 100vh;
  display: grid;
  transition: 0.25s ease-in-out;
  grid-template-rows: 90px 1fr 80px;
  grid-template-columns: $sidebar-width calc(100% - $sidebar-width);
  grid-template-areas:
    'sidebar header'
    'sidebar container'
    'footer footer';
}

.collapse-sidebar {
  grid-template-columns: $sidebar-collapsed-width 1fr;
}

.collapse-sidebar > .sidebar {
  width: $sidebar-collapsed-width !important;
}

.sidebar {
  grid-area: sidebar;
  background-color: white;
  z-index: 1;
  position: fixed;
  width: $sidebar-width;
  height: 100%;
  border-right: 1px solid $color-gray-light;
  display: block;
  border-radius: 0px 10px 10px 0px;
}

.header {
  grid-area: header;
  // background-color: black;
  max-width: calc(1900px - $sidebar-width);
}

.header-notifications{
  width: 90%;
}

.container {
  grid-area: container;
  // background-color: gray;
  padding: 10px 55px 80px;
  max-width: calc(1900px - $sidebar-width);
}

.footer {
  grid-area: footer;
  padding-left: $sidebar-width;
  background-color: $color-blue-dark;
}

.footer-w-sidebar-collapsed {
  padding-left: $sidebar-collapsed-width;
}

@media screen and (max-width: 1024px) {
  // .main-container {
  //   display: block;
  //   overflow: hidden;
  // }

  .sidebar {
    display: none;
  }

  .container {
    padding: 19px 25px 80px;
  }
  .footer {
    padding-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 1rem;
  }
}

.breadcrumb-container {
  display: block;

  @media screen and (min-width: 1025px) {
    display: none;
  }
}

.cr-resumen-table {
  height: 100%;
  position: relative;
}

.cr-resumen-table :global(.ag-cell) {
  padding-left: 10px;
  padding-right: 10px;
}

.cr-resumen-table :global(.ag-header-cell) {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 13px;
}

.cr-resumen-table :global(.ag-header-cell-resize) {
  display: none;
}

.cr-resumen-table :global(.ag-header-cell-text) {
  font-size: 13px;
}

.cr-resumen-table :global(.ag-header-cell-label) {
  justify-content: flex-start;
}

.cr-resumen-table :global(.ag-sort-indicator-container) {
  display: none
}
@media screen and (max-width: 1025px) {

  .container {
    max-height: 1fr;
    height: auto;
  }
  .main-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'header'
      'container'
      'footer';
  }
}

@media screen and (max-width: 600px) {

  .container {
    max-height: fit-content;
    height: auto;
  }
}