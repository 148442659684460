@import "@/shared/variables";

.footer {
    width: 100%;
    color: white;
    max-width: calc(1900px - $sidebar-width);
}

.container {
    margin: auto;
    display: flex;
    gap: 10px;
    justify-content: space-evenly;
    padding: 20px;
    user-select: none;
}

.container p {
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin: auto;
}

.icon {
    height: 40px;
    width: auto;
    display: flex;
    margin: auto;
}

.icon-cofepris {
    height: 30px;
    width: auto;
    display: flex;
    margin: auto;
}
.FooterPaymentsDD {
    display: flex;
    gap: 20px;

    svg{
        height: 30px;
        width: 37px;
    }
}

.footer-text {
    margin: auto;
    font-size: 12px;
    cursor: pointer;
    text-decoration: none;
    color: white;
}

.copyright-text {
    color: $color-white;
}

@media screen and (max-width:768px) {
    .container {
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-areas:
            "icon icon icon icon"
            "label1 label1 label3 label3"
            "label2 label2 label2 label2";
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        gap: 9px;

        p {
            font-size: 8px;
            line-height: 11px;
            font-weight: 400;
        }
    }

    .icon {
        grid-area: icon;
        margin-left: 25%;
    }

    .icon-cofepris {
        grid-area: icon;
        // width: 30%;
        margin-right: 17%;
    }

    .container p.aviso {
        grid-area: label1;text-align: end;
        margin: 0 6px 0 auto;
    }

    .container p.terminos {
        grid-area: label3;
        margin: 0 auto 0 6px;
    }

    .copyright-text {
        grid-area: label2;
        p {
            color: #FFFFFF;
            font-weight: 700;
        }
    }

}