@import '@/shared/variables';

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

.message-loader {
  color: $color-white;
  font-weight: bold;
  width: 400px;
  text-align: center;
  font-size: 24px;

  @media screen and (max-width: 768px) {
    padding: 0 33px;
  }
}
